import React, { useEffect } from "react"
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstone from "cornerstone-core";
import dicomParser from "dicom-parser";
import NewTransaction from "./pages/NewTransaction"
import Transactions from "./pages/Transactions";
import Layout from "./pages/Layout";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import FirebaseProvider, { AuthWrapper } from "./FirebaseContext";
import "./App.css"
import "antd/dist/antd.css";
import Landing from "./pages/Landing";
import SignIn from "./pages/SignIn";
import TransactionDetails from "./pages/TransactionDetails";
import Registration from "./pages/Registration";

const App = () => {
  useEffect(() => {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      webWorkerTaskPaths: [
        "https://unpkg.com/cornerstone-wado-image-loader@4.1.3/dist/610.bundle.min.worker.js",
        "https://unpkg.com/cornerstone-wado-image-loader@4.1.3/dist/888.bundle.min.worker.js"
      ],
      maxWebWorkers: 1,
      startWebWorkersOnDemand: true,
      taskConfiguration: {
        decodeTask: {
          initializeCodecsOnStartup: false,
          usePDFJS: false,
          strict: false,
        },
      },
    });
    window.cornerstone = cornerstone
  }, [])

  return (
    <FirebaseProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route element={<AuthWrapper />}>
              <Route path="transactions">
                <Route path=":transactionId" element={<TransactionDetails />} />
                <Route path="new" element={<NewTransaction />} />
                <Route index element={<Transactions />} />
              </Route>
              <Route path="/registration" element={<Registration />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route index element={<Landing />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter >
    </FirebaseProvider >
  );
}

export default App;
