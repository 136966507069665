import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { ulid } from "ulid"
import dicomParser from "dicom-parser"
import { data } from 'dcmjs'
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { Steps, Radio, Button, Space, Typography, Card, Collapse, Checkbox, Upload, Alert, Descriptions } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FirebaseContext } from "../FirebaseContext";
import styles from "./NewTransaction.module.css"
import { InputDataSummary } from '../components/DICOM';
import { isMobile } from 'react-device-detect';

const { Dragger } = Upload;
const { Title } = Typography;
const { Step } = Steps;
const { Panel } = Collapse;

const NewTransaction = () => {
  const [step, setStep] = useState(1);
  const [inputType, setInputType] = useState(isMobile ? "samples" : "upload");
  const [selectedSample, setSelectedSample] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [uploadCount, setUploadCount] = useState(0)
  const [transactions, setTransactions] = useState({})
  const [fileObjs, setFileObjs] = useState({}) // {[file.uid]: File}
  const [fileList, setFileList] = useState([]) // [File]
  const [tasks, setTasks] = useState({}) // {[file.uid]: [tagTask: Promise, imageTask: Promise]}
  const [dicoms, setDicoms] = useState({}) // {[file.uid]: {file: File, tags: {[tagKey]: tagValue}, image: Object}}
  const [isDirectory, setIsDirectory] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [isAnonymize, setIsAnonymize] = useState(false)

  let { firebase, authUser, license } = useContext(FirebaseContext)

  let navigate = useNavigate()

  const props = {
    name: 'file',
    multiple: true,
    directory: isDirectory, // 
    showUploadList: fileList.length,
    beforeUpload: () => false,
    async onChange(info) {
      let _fileObjs = { ...fileObjs }

      // UI에서 파일을 삭제했다면 fileObjs에서 항목을 제거
      if (info.file.status === "removed") {
        delete _fileObjs[info.file.uid]
        setFileObjs(_fileObjs)
        return
      }

      // 이미 등록된 파일이라면 state 갱신 X
      if (info.file.uid in _fileObjs) return

      _fileObjs[info.file.uid] = info.file

      // 이름이 겹치는 경우 기존 파일을 삭제
      for (let file of fileList) {
        Object.values(_fileObjs).forEach(otherFile => {
          if (file.name === otherFile.name && file.uid !== otherFile.uid) {
            delete _fileObjs[file.uid]
            setFileObjs(_fileObjs)
          }
        })
      }

      setFileObjs((prev) => ({ ...prev, ..._fileObjs }))
    },
    fileList,
  };

  const getAnonymizedTags = (file) => {
    const _tags = {}
    return new Promise(reslove => {
      anonymize(file)
        .then(anonymizedFile => parseDicom(anonymizedFile)
          .then(dataSet => {

            const prettyTag = tag => `(${tag.substr(1, 4).toUpperCase()},${tag.substr(5, 4).toUpperCase()})`
            Object.values(dataSet.elements).forEach((v) => _tags[prettyTag(v.tag)] = dataSet.string(v.tag) || "")
          })
        )
      reslove(_tags)
    })
  }

  const getTags = (file) => {
    return new Promise(resolve => {
      parseDicom(file)
        .then(dataSet => {
          const _tags = {}
          const prettyTag = tag => `(${tag.substr(1, 4).toUpperCase()},${tag.substr(5, 4).toUpperCase()})`
          Object.values(dataSet.elements).forEach((v) => _tags[prettyTag(v.tag)] = dataSet.string(v.tag) || "")
          resolve(_tags)
        })
    })

  }

  const getImage = (file) => {
    return new Promise(resolve => {
      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
      cornerstone.loadImage(imageId).then(image => {
        resolve(image)
      })
    })
  }

  const validate = () => {
    let _errorMessages = [...errorMessages]

    if (!fileList.length) _errorMessages.push("Please select one or more file")
    else {
      const files = Object.values(fileObjs).map(f => f.name)
      const hasDuplicates = files.some((element, index) => {
        return files.indexOf(element) !== index
      });
      if (hasDuplicates) {
        _errorMessages.push("File names must be unique")
      }
    }

    // modality === CT 인지 여부 체크, SOP CLASS === 1.2.840.10008.5.1.4.1.1.2 인지 여부 체크
    if (!(Object.values(dicoms).every(dicom => dicom.tags['(0008,0060)'] === 'CT') && Object.values(dicoms).every(dicom => dicom.tags['(0008,0016)'] === '1.2.840.10008.5.1.4.1.1.2'))) {
      _errorMessages.push("Please select only CT Image")
    }

    // modality === MONOCHROME 인지 여부 체크
    if (!Object.values(dicoms).every(dicom => dicom.tags['(0028,0004)'] === 'MONOCHROME1' || dicom.tags['(0028,0004)'] === 'MONOCHROME2')) {
      _errorMessages.push("Please select MONOCHROME1 or MONOCHROME2 Image")
    }

    const invalidTypes = ["SCOUT", "TOPOGRAM", "SCREEN", "CAPTURE", "LOCALIZER",]

    // Image Type
    if (Object.values(dicoms).some(dicom => invalidTypes.some(invalidType => ('(0008,0008)' in dicom.tags && dicom.tags['(0008,0008)'].toUpperCase().includes(invalidType)))) ||
      Object.values(dicoms).some(dicom => invalidTypes.some(invalidType => ('(0008,103E)' in dicom.tags && dicom.tags['(0008,103E)'].toUpperCase().includes(invalidType))))) {
      _errorMessages.push("Please select Original/Primary Image")
    }

    if (_errorMessages.length) {
      setErrorMessages(_errorMessages)
      return false
    }

    return true
  }

  const anonymize = async (file) => {
    let dcm = data.DicomMessage.readFile(await file.arrayBuffer());

    let removeTags = ['00080080', '00080096', '00081048', '00081049', '00081050', '00081052', '00081060', '00081062', '00100050',
      '00100101', '00101000', '00101001', '00101002', '00101010', '00101040', '00101060', '00100021', '00100032', '00101000', '00101001', '00101005', '00102150',
      '00102152', '00102154', '00380300', '00380400', '00080021', '00080022', '00080024', '00080025', '0008002A', '00080031', '00080032', '00080034', '00080035',
      '00080081', '00080092', '00080094', '00081040', '00081070', '0040A120', '0040A121', '0040A122']
    let replaceZeroTags = ['00080050', '00100030', '00100010', '00100020', '00100040', '00080020', '00080023', '00080030', '00080033', '00080090', '00200010']
    let replaceDummyTags = ['0040A123']

    // removeTags
    for (let tag of removeTags) {
      if (tag in dcm.dict) delete dcm.dict[tag]
    }
    // replaceZeroTags
    for (let tag of replaceZeroTags) {
      if (tag in dcm.dict) {
        let changeValue
        switch (dcm.dict[tag].VR) {
          case 'DA':
            dcm.upsertTag(tag, dcm.dict[tag].VR, '00000000')
            break
          case 'TM':
            dcm.upsertTag(tag, dcm.dict[tag].VR, 'Anonymized')
            break
          default:
            dcm.upsertTag(tag, dcm.dict[tag].VR, 'Anonymized')
            break;
        }

      }
    }
    for (let tag of replaceDummyTags) {
      if (tag in dcm.dict) dcm.upsertTag(tag, dcm.dict[tag].VR, 'Anonymized')
    }
    return new File([new Blob([await dcm.write()])], file.name)

  }

  // TODO
  const compress = () => {

  }

  const handleNextStep = () => {
    if (step === 2) {
      if (inputType === "samples") navigate("/transactions?datasource=sample")
      else setStep(3)
    } else if (step === 3) {
      if (validate()) setStep(4)
    }
  }

  const handleSubmit = async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    setIsLoading(true)

    const files = isAnonymize ? await Promise.all(fileList.map(anonymize)) : fileList

    // get series uid from first image
    const dataSet = await parseDicom(files[0])
    const tags = {}
    const prettyTag = tag => `(${tag.substr(1, 4).toUpperCase()},${tag.substr(5, 4).toUpperCase()})`
    Object.values(dataSet.elements).forEach((v) => tags[prettyTag(v.tag)] = dataSet.string(v.tag) || "")

    // upload all images to dicoms/series_uid storage
    const storageId = ulid()
    let promises = []
    for (const file of files) {
      const promise = new Promise(resolve => {
        const storageRef = ref(firebase.storage, `dicoms/${storageId}/${file.name}`);
        uploadBytes(storageRef, file, {
          cacheControl: 'private,max-age=3000',
          customMetadata: {
            access: String(authUser.uid)
          }
        }).then(() => {
          setUploadCount(s => s + 1)
          resolve()
        })
      })
      // 로컬 에뮬레이터용 요청 분산
      if (window.location.host.startsWith("localhost")) await promise
      promises.push(promise)
    }
    await Promise.all(promises)

    // update database
    // create transaction
    const docRef = await addDoc(collection(firebase.db, `licenses/${authUser.uid}/transactions`), {
      solution: "ClariCT.AI",
      input: {
        tags,
        storagePath: `dicoms/${storageId}`
      },
      history: [{ status: "Ready", timestamp: new Date() }],
      createdAt: new Date(),
    });

    setIsLoading(false)

    // move to details page
    navigate(`/transactions/${docRef.id}`)
  }

  const parseDicom = async (dicomFile) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = function (file) {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);
        try {
          const dataSet = dicomParser.parseDicom(byteArray);
          resolve(dataSet)
        } catch (e) {
          setErrorMessages(s => {
            const msg = "Unable to parse DICOM. Please check if all files are in valid DICOM format."
            if (s.includes(msg)) return s
            else return [...s, msg]
          })
        }
      };
      reader.readAsArrayBuffer(dicomFile);
    })
  }

  useEffect(() => {
    (async () => {
      const q = query(collection(firebase.db, `licenses/${authUser.uid}/transactions`), where("isSample", "==", true));
      const querySnapshot = await getDocs(q);
      let _transactions = {}
      querySnapshot.forEach((doc) => {
        const transaction = doc.data()
        _transactions[doc.id] = transaction
      });
      setTransactions(_transactions)
    })()
  }, [])

  useEffect(() => {
    let newFileList = Object.values(fileObjs)
    setFileList(() => newFileList)

    let _tasks = { ...tasks }
    let _dicoms = { ...dicoms }
    newFileList.forEach(file => {
      if (!(file.uid in _tasks) && !(file.uid in dicoms)) {
        const tagTask = getTags(file)
        const imageTask = getImage(file)
        const anonmyizeTask = getAnonymizedTags(file)
        _tasks[file.uid] = [file, tagTask, imageTask, anonmyizeTask]
      }
    })
    setTasks(_tasks)

    // fileObjs에서 삭제된 데이터는 dicoms에서도 삭제
    Object.keys(dicoms).forEach(uid => {
      if (!(uid in fileObjs)) {
        delete _dicoms[uid]
      }
    })
    setDicoms(_dicoms)

    setErrorMessages([])
  }, [fileObjs])

  useEffect(() => {
    (async () => {
      let uids = Object.keys(tasks)
      let _dicoms = { ...dicoms }
      for (const uid of uids) {
        const task = tasks[uid]
        let [file, tags, image, anonymizedTags] = await Promise.all(task)
        _dicoms[uid] = { file, tags, image, anonymizedTags }
        delete tasks[uid]
      }
      setDicoms(_dicoms)
    })()
  }, [tasks])

  return (
    <section className={styles.section}>
      <div className={styles.contents}>
        {!(license.credits) ?
          <>
            <Alert
              message="Error"
              description={<span>You need credits for demo. Contact david3014@claripi.com or click <a href="https://claripi.com/contact-us/">here</a> to request for extra credits</span>}
              type="error"
              showIcon
            />
            <Card>
              <Descriptions title="License info">
                <Descriptions.Item label="Type">Demo license</Descriptions.Item>
                <Descriptions.Item label="Expires at">{license.expiresAt.toDate().toISOString().replace('T', ' ').substring(0, 19)}</Descriptions.Item>
                <Descriptions.Item label="Credits">{license.credits}</Descriptions.Item>
              </Descriptions>
            </Card>
          </>
          :
          <>
            <Card>
              <Steps size="small" current={step - 1}>
                <Step title="Select a solution" />
                <Step title="Data entry method" />
                <Step title="Input data" />
                <Step title="Upload" />
              </Steps>
            </Card>
            {(step === 1) &&
              <>
                <Card>
                  <Descriptions title="License info">
                    <Descriptions.Item label="Type">Demo license</Descriptions.Item>
                    <Descriptions.Item label="Credits">{license.credits}</Descriptions.Item>
                    <Descriptions.Item label="Expires at">{license.expiresAt.toDate().toISOString().replace('T', ' ').substring(0, 19)}</Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card>
                  <Title level={3}>Select a solution to demo.</Title>
                  <br />
                  <Radio.Group value={"ClariCT.AI"}>
                    <Space direction="vertical">
                      <Radio value="ClariCT.AI">ClariCT.AI</Radio>
                      <Radio value="ClariPulmo" disabled>ClariPulmo (Available soon)</Radio>
                      <Radio value="ClariSIGMAM" disabled>ClariSIGMAM (Available soon)</Radio>
                    </Space>
                  </Radio.Group>
                  <br />
                  <br />
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ flexGrow: "1" }} />
                    <Button type="primary" onClick={e => setStep(2)}>Next</Button>
                  </div>
                </Card>
              </>
            }
            {(step === 2) &&
              <Card>
                <Title level={3}>How would you provide the input data?</Title>
                <br />
                <div>
                  <Radio.Group onChange={(e) => setInputType(e.target.value)} value={inputType}>
                    <Space direction="vertical">
                      {isMobile ?
                        <>
                          <Radio value="samples">Select from sample datasets</Radio>
                          <Radio value="upload">Upload new files</Radio>
                        </>
                        :
                        <>
                          <Radio value="upload">Upload new files</Radio>
                          <Radio value="samples">Select from sample datasets</Radio>
                        </>
                      }
                    </Space>
                  </Radio.Group>
                </div>
                <br />
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button type="default" onClick={e => setStep(1)}>Back</Button>
                  <div style={{ flexGrow: "1" }} />
                  <Button type="primary" onClick={handleNextStep}>Next</Button>
                </div>
              </Card>
            }
            {(step === 3) &&
              <Card>
                <div>
                  <Title level={3}>Please select a DICOM series.</Title>
                  <br />
                  <p>Selected files: ({Array.from(fileList).length})</p>
                  <Collapse>
                    <Panel header="options">
                      <Title level={5}>Selector mode</Title>
                      <Radio.Group value={isDirectory ? "true" : "false"} onChange={e => setIsDirectory(e.target.value === "true")} buttonStyle="solid">
                        <Radio.Button value="false">Select files</Radio.Button>
                        <Radio.Button value="true">Select a directory</Radio.Button>
                      </Radio.Group>
                      <br />
                      <br />
                      <Title level={5}>DICOM anonymization</Title>
                      <Checkbox checked={isAnonymize} onChange={e => setIsAnonymize(e.target.checked)}>Apply</Checkbox>
                    </Panel>
                  </Collapse>
                  <form onSubmit={handleSubmit}>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      {isDirectory ?
                        <p className="ant-upload-text">Click or drag a folder to this area to upload</p>
                        :
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      }
                      {/* <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                      </p> */}
                      <div style={{ width: "100%", padding: "10px 20px", textAlign: "left", color: "rgba(0, 0, 0, 0.45)" }}>
                        <ul>
                          <li>Files must be in DICOM format</li>
                          <li>DICOM data must be a valid CT series</li>
                        </ul>
                      </div>
                    </Dragger>
                    <br />
                    {errorMessages.map((msg, i) => <Alert key={i} message={msg} type="error" showIcon />)}
                    <br />
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button type="default" onClick={e => setStep(2)}>Back</Button>
                      <div style={{ flexGrow: "1" }} />
                      {(fileList.length && fileList.length !== Object.keys(dicoms).length) ?
                        <Button type="primary" loading={true}>Loading</Button>
                        :
                        <Button type="primary" onClick={handleNextStep}>Next</Button>
                      }
                    </div>
                  </form>
                </div>
              </Card>
            }
            {(step === 4) &&
              <>
                <Alert
                  message="Caution"
                  description={
                    <>
                      <span>The demo is intended for product evaulation purpose only. Do not use it in clinical environment. </span>
                    </>
                  }
                  type="info"
                  showIcon
                />
                <Card>
                  <Title level={3}>Confirm your data before uploading</Title>
                  <ul>
                    <li>Press upload button when ready</li>
                  </ul>
                  <br />
                  <InputDataSummary images={Object.values(dicoms).map(d => d.image)} tags={
                    isAnonymize ? Object.values(dicoms)[0].anonymizedTags : Object.values(dicoms)[0].tags} />
                  <br />
                  <br />
                  {isLoading &&
                    <p>Uploading files... ({uploadCount}/{Array.from(fileList).length})</p>
                  }
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button type="default" onClick={e => setStep(3)}>Back</Button>
                    <div style={{ flexGrow: "1" }} />
                    <Button type="primary" onClick={handleSubmit} loading={isLoading}>Upload</Button>
                  </div>
                </Card>
              </>
            }
          </>
        }
      </div>
    </section >
  )
}

export default NewTransaction;