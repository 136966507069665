import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyCWpQwC2m56RiHIy01cSuh-lFZTezca43s",
  authDomain: "claripi-web-demo.firebaseapp.com",
  projectId: "claripi-web-demo",
  storageBucket: "claripi-web-demo.appspot.com",
  messagingSenderId: "556338808475",
  appId: "1:556338808475:web:185ce2f264fec90d54a83e",
  measurementId: "G-2BMFQV0C3Z",
};

class Firebase {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
    this.auth = getAuth();
    this.storage = getStorage(this.app);
    this.db = getFirestore(this.app)
    this.functions = getFunctions(this.app);

    if (window.location.hostname === "localhost") {
      connectAuthEmulator(this.auth, "http://localhost:9099")
      connectStorageEmulator(this.storage, "localhost", 9199);
      connectFirestoreEmulator(this.db, 'localhost', 8080);
      connectFunctionsEmulator(this.functions, "localhost", 5001);
    }
  }
}

export default Firebase;
