import { useContext } from "react"
import { useState, useEffect, createContext } from "react"
import { useLocation, Navigate, useNavigate } from "react-router"
import Firebase from "./firebase"
import { httpsCallable } from "firebase/functions"
import { signInWithCustomToken, browserLocalPersistence } from "firebase/auth"
import { doc, getDoc, Timestamp } from "firebase/firestore"
import { Outlet } from "react-router"
import { Alert, Spin } from 'antd';

import * as Sentry from "@sentry/react";
export const FirebaseContext = createContext({})

const FirebaseProvider = ({ children }) => {
  const [firebase, setFirebase] = useState(null)
  const [authUser, setAuthUser] = useState(null)
  const [authPending, setAuthPending] = useState(true)
  const [license, setLicense] = useState(null)

  useEffect(() => {
    if (!firebase) {
      const _firebase = new Firebase()
      setFirebase(_firebase)
      _firebase.auth.onAuthStateChanged((authUser) => {
        Sentry.setUser({ id: authUser?.uid, name: 'init' });
        setAuthUser(authUser)
        setAuthPending(false)
      })
    }
  }, [firebase])

  return (
    <FirebaseContext.Provider value={{ firebase, authUser, setAuthUser, license, setLicense, authPending }}>
      {firebase && children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseProvider

export const AuthWrapper = () => {
  const { firebase, authUser, setAuthUser, license, authPending, setLicense } = useContext(FirebaseContext)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (authUser && !license) {
      const docRef = doc(firebase.db, "licenses", authUser.uid)
      getDoc(docRef).then(snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data()
          Sentry.setUser({ id: snapshot.id, name: data?.userInfo?.name, email: data?.userInfo?.email });
          setLicense(snapshot.data())
        }
      })
    }

    if (license?.expiresAt) {
      const isExpired = license.expiresAt.toDate().getTime() < Date.now()
      if (isExpired) firebase.auth.signOut().then(() => { setAuthUser(null); navigate("/"); })
    }
  }, [authUser, license, firebase])

  if (authPending) return <Spin tip="Loading...">
    <div style={{ width: "100%", height: "100vh" }} />
  </Spin>

  if (!authUser) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!!license && !(location.pathname.includes("/registration")) && (!(license?.userInfo?.name) || !(license?.userInfo?.email))) {
    return <Navigate to="/registration" state={{ from: location }} replace />;
  }

  return !!(license) && <Outlet />;
}