import React, { useState, useEffect, useContext, useRef, useCallback } from "react"
import cornerstone from "cornerstone-core";
import dicomParser from "dicom-parser";
import { Radio, Button, Slider, Typography, Space, Input, Spin, Steps, Collapse, Alert, Switch, Card, Descriptions, Table, Tag, Tooltip } from "antd";
import { FirebaseContext } from "../FirebaseContext";
import { ref, listAll, getDownloadURL, getBytes, getBlob } from "firebase/storage"
import { useLocation, useParams } from "react-router";
import { onSnapshot, doc } from "firebase/firestore";
import styles from "./TransactionDetails.module.css"
import { DICOMDualViewer, DICOMViewer, SummaryBase } from "../components/DICOM";
import { isMobile } from "react-device-detect";
import { QuestionCircleOutlined } from "@ant-design/icons"

const { Title } = Typography
const { Step } = Steps;
const { Panel } = Collapse;

async function retryOnTimeout(promise, ms = 10000, max = 2, current = 0) {
  let timer;
  const res = await Promise.race([
    promise,
    new Promise(resolve => {
      timer = setTimeout(() => resolve('timeout'), ms);
    })
  ]).finally(() => clearTimeout(timer));

  if (res === 'timeout') {
    if (current <= max) {
      console.log("timed out!!")
      return await retryOnTimeout(promise, ms, max, current + 1)
    }
    throw new Error(`Max retry reached`);
  }
  return res;
}

async function timeout(promise, ms) {
  let timer;
  const res = await Promise.race([
    promise,
    new Promise(resolve => {
      timer = setTimeout(() => resolve('timeout'), ms);
    })
  ]).finally(() => clearTimeout(timer));

  if (res === 'timeout') {
    throw new Error(`timeout`);
  }
  return res;
}

function StatefulPromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isFulfilled) return promise;

  // Set initial state
  var isPending = true;
  var isRejected = false;
  var isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  var result = promise.then(
    function (v) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function (e) {
      isRejected = true;
      isPending = false;
      throw e;
    }
  );

  result.isFulfilled = function () { return isFulfilled; };
  result.isPending = function () { return isPending; };
  result.isRejected = function () { return isRejected; };
  return result;
}

const TransactionDetails = () => {
  const [leftImageRefs, setLeftImageRefs] = useState({})
  const [leftUrls, setLeftUrls] = useState({})
  const [leftDLTasks, setLeftDLTasks] = useState({})
  const [leftImages, setLeftImages] = useState({})
  const [rightImageRefs, setRightImageRefs] = useState({})
  const [rightUrls, setRightUrls] = useState({})
  const [rightDLTasks, setRightDLTasks] = useState({})
  const [rightImages, setRightImages] = useState({})
  const [transaction, setTransaction] = useState(null)
  const [updateTick, setUpdateTick] = useState(0)
  const [imageIndex, setImageIndex] = useState("1")
  const [stepIndex, setStepIndex] = useState(null)
  const [preload, setPreload] = useState(undefined)
  const [overlay, setOverlay] = useState(undefined)
  const [window, setWindow] = useState({ width: 0, center: 0 })
  const [preset, setPreset] = useState("Image default")
  const [options, setOptions] = useState({
    "Image default": { width: 0, center: 0 },
    "Soft tissue": { width: 350, center: 50 },
    "Lung": { width: 1600, center: -600 },
    "Bone": { width: 2000, center: 300 },
    "Brain": { width: 80, center: 40 },
  })

  const { firebase, authUser } = useContext(FirebaseContext)

  const params = useParams();
  const prevTick = usePrevious(updateTick)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  async function timeout(promise, ms) {
    let timer;
    const res = await Promise.race([
      promise,
      new Promise(resolve => {
        timer = setTimeout(() => resolve('timeout'), ms);
      })
    ]).finally(() => clearTimeout(timer));

    if (res === 'timeout') {
      throw new Error(`timeout`);
    }
    return res;
  }

  const downloadAndLoadImage = (ref, name, urls, setURls, setImages) => {
    const controller = new AbortController();
    const { signal } = controller;

    const task = StatefulPromise(timeout(new Promise(async resolve => {
      let url = null
      if (name in urls) url = urls[name]
      else {
        url = await getDownloadURL(ref)
        setURls(s => { return { ...s, [name]: url } })
      }
      const response = await fetch(url, { signal })
      const blob = await response.blob()
      const objUrl = await URL.createObjectURL(blob)
      const image = await cornerstone.loadImage(`wadouri:${objUrl}`)
      setImages(s => { return { ...s, [name]: image } })
      resolve(image)
    }), 10000))

    return { controller, task }
  }

  const getImageRefs = async (storagePath, callback) => {
    const items = (await listAll(ref(firebase.storage, storagePath))).items
    const refs = {}
    items.forEach(item => refs[item.name] = item)
    callback(refs)
  }

  useEffect(() => {
    let index = imageIndex - 1
    const leftKeys = Object.keys(leftImages)
    if (index < leftKeys.length) {
      const k = leftKeys[index]
      if (!(k in leftDLTasks) && !(leftImages[k])) setLeftDLTasks(s => { return { ...s, [k]: downloadAndLoadImage(leftImageRefs[k], k, leftUrls, setLeftUrls, setLeftImages) } })
    }
    const rightKeys = Object.keys(rightImages)
    if (index < rightKeys.length) {
      const k = rightKeys[index]
      if (!(k in rightDLTasks) && !(rightImages[k])) setRightDLTasks(s => { return { ...s, [k]: downloadAndLoadImage(rightImageRefs[k], k, rightUrls, setRightUrls, setRightImages) } })
    }
  }, [imageIndex, leftImages, rightImages])

  useEffect(() => {
    document.querySelector('html').scrollTop = 0
    document.body.scrollTop = 0

    // transaction subscribe
    const unsubscribe = onSnapshot(doc(firebase.db, `licenses/${authUser.uid}/transactions`, params.transactionId), (doc) => {
      setTransaction(doc.data())
    });
    // updateTick
    const intCancel = setInterval(() => setUpdateTick(s => s + 1), 500)
    return () => {
      unsubscribe()
      clearInterval(intCancel)
      Object.entries(leftDLTasks).forEach(([k, v]) => {
        if (!leftImages[k]) v.controller.abort()
      })
      Object.entries(rightDLTasks).forEach(([k, v]) => {
        if (!rightImages[k]) v.controller.abort()
      })
    }
  }, [])

  useEffect(() => {
    if (transaction) {
      getImageRefs(transaction.input.storagePath, setLeftImageRefs)
      if (transaction.output?.storagePath) getImageRefs(transaction.output.storagePath, setRightImageRefs)

      if (transaction?.input?.tags) {
        const width = transaction.input.tags["(0028,1051)"].split("\\")[0]
        const center = transaction.input.tags["(0028,1050)"].split("\\")[0]
        setOptions(s => { return { ...s, "Image default": { width, center } } })
        setWindow({ width, center })
      }

      if (transaction?.history[transaction?.history.length - 1].status === "Ready") setStepIndex(1)
      if (transaction?.history[transaction?.history.length - 1].status === "Processing") setStepIndex(2)
      if (transaction?.history[transaction?.history.length - 1].status === "Completed") setStepIndex(3)
    }
  }, [transaction])

  useEffect(() => {
    let _preset = undefined
    Object.entries(options).forEach(([key, value]) => {
      if (window.center == value.center && window.width == value.width) _preset = key
    })
    setPreset(_preset)
  }, [window, options])

  useEffect(() => {
    if (preload === undefined) {
      if ("preloadImage" in localStorage) {
        const savedValue = localStorage.getItem("preloadImage") === "true"
        setPreload(savedValue)
      } else {
        setPreload(false)
      }
    } else {
      localStorage.setItem("preloadImage", String(preload))
    }
  }, [preload])

  useEffect(() => {
    if (overlay === undefined) {
      if ("displayOverlay" in localStorage) {
        const savedValue = localStorage.getItem("displayOverlay") === "true"
        setOverlay(savedValue)
      } else {
        setOverlay(true)
      }
    } else {
      localStorage.setItem("displayOverlay", String(overlay))
    }
  }, [overlay])

  useEffect(() => {
    if (Object.keys(leftImageRefs).length) {
      let images = {}
      Object.entries(leftImageRefs).forEach(([k, v]) => {
        images[k] = null
      })
      setLeftImages(s => { return { ...images, ...s } })
      setImageIndex(String(Math.ceil((Object.keys(leftImageRefs).length / 2))))
    }
    if (Object.keys(rightImageRefs).length) {
      let images = {}
      Object.entries(rightImageRefs).forEach(([k, v]) => {
        images[k] = null
      })
      setRightImages(s => { return { ...images, ...s } })
    }
  }, [leftImageRefs, rightImageRefs])

  const MAX_TASKS = 10
  useEffect(() => {
    if (preload && prevTick < updateTick) {
      [
        [leftImageRefs, leftDLTasks, setLeftDLTasks, leftUrls, setLeftUrls, setLeftImages, leftImages],
        [rightImageRefs, rightDLTasks, setRightDLTasks, rightUrls, setRightUrls, setRightImages, rightImages]
      ].forEach(([imageRefs, dLTasks, setDLTasks, urls, setUrls, setImages, images]) => {
        if (Object.keys(imageRefs).length) {
          let tasks = {}
          Object.entries(dLTasks).forEach(([k, v]) => {
            if (v.task.isPending()) tasks[k] = v
            if (v.task.isRejected()) tasks[k] = downloadAndLoadImage(imageRefs[k], k, urls, setUrls, setImages)
          })
          let vacancy = MAX_TASKS - Object.keys(dLTasks).length
          vacancy = vacancy >= 0 ? vacancy : 0
          let todos = Object.keys(images).filter(k => !images[k]).filter(k => !Object.keys(tasks).includes(k));
          Array.from(Array(Math.min(vacancy, todos.length)).keys()).forEach(i => {
            let k = todos[i]
            tasks[k] = downloadAndLoadImage(imageRefs[k], k, urls, setUrls, setImages)
          })
          setDLTasks(tasks)
        }
      })
    }
  }, [updateTick, preload, leftImageRefs, leftDLTasks, setLeftDLTasks, leftUrls, setLeftUrls, setLeftImages, leftImages, rightImageRefs, rightDLTasks, setRightDLTasks, rightUrls, setRightUrls, setRightImages, rightImages])


  return (
    <section className={styles.section}>
      <div className={styles.contents}>
        <Card>
          <Steps size="small" current={stepIndex}>
            <Step title="Loading input data" />
            <Step title="Waiting for task queue" />
            <Step title="Processing" />
            <Step title="Completed" />
          </Steps>
        </Card>
        {transaction?.history[transaction?.history.length - 1].status === "Ready" &&
          <Alert
            message="Waiting for task queue"
            description="Your request are sent to the server. Please wait."
            type="warning"
            showIcon
          />
        }
        {transaction?.history[transaction?.history.length - 1].status === "Processing" &&
          <Alert
            message="Processing"
            description={`The data is being processed. You might able to see some of the result data.`}
            type="info"
            showIcon
          />
        }
        {transaction?.history[transaction?.history.length - 1].status === "Completed" &&
          <Alert
            message="Process Completed"
            description="Now you can view the whole result"
            type="success"
            showIcon
          />
        }

        <Card className="dicom-card">
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
            <div style={{ flex: "1", minWidth: "200px" }}>
              <Title level={4}>Original:</Title>
              <DICOMViewer image={Object.values(leftImages)[imageIndex - 1]} windowWidth={window.width} windowCenter={window.center} tags={overlay && transaction?.input?.tags} />
            </div>
            <div style={{ flex: "1", minWidth: "200px" }}>
              <Title level={4}>Denoised:</Title>
              <DICOMViewer image={Object.values(rightImages)[imageIndex - 1]} windowWidth={window.width} windowCenter={window.center} tags={overlay && transaction?.input?.tags} />
            </div>
          </div>
        </Card>

        <SummaryBase tags={transaction?.input.tags || {}} activeKey={["controls"]}>
          <Panel header="controls" key="controls">
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <label>
                <Title level={4}>Image Index: {imageIndex} / {Object.keys(leftImageRefs).length}</Title>
                <Slider min={1} max={Object.keys(leftImageRefs).length} value={imageIndex} onChange={setImageIndex} />
              </label>
              <label>
                <Title level={4}>Preload Images:</Title>
                <Switch checked={preload} onChange={e => setPreload(e)} />
                <br />
                <br />
                {preload ?
                  <span>Enabled: downloads all images in the background beforehand.</span>
                  :
                  <span>Disabled: downloads only currently selected images to save network usage. </span>
                }
              </label>
              <label>
                <Title level={4}>Display Overlay:</Title>
                <Switch checked={overlay} onChange={e => setOverlay(e)} />
                <br />
                <br />
                {overlay ?
                  <span>Enabled: display metadata on images</span>
                  :
                  <span>Disabled: hide metadata and display only the images</span>
                }
              </label>
              <label>
                <Title level={4}>Level Presets:</Title>
                <Radio.Group value={preset} onChange={e => setWindow(options[e.target.value])} buttonStyle="solid">
                  {Object.keys(options).map(k =>
                    <Radio.Button key={k} value={k}>{k}</Radio.Button>
                  )}
                </Radio.Group>
              </label>
              <label>
                <Title level={4}>Window Width:</Title>
                <Input value={window.width} onChange={e => new RegExp(/^[+-]?[0-9]*$/).test(e.target.value) && setWindow({ width: e.target.value, center: window.center })} />
              </label>
              <label>
                <Title level={4}>Window Center:</Title>
                <Input value={window.center} onChange={e => new RegExp(/^[+-]?[0-9]*$/).test(e.target.value) && setWindow({ width: window.width, center: e.target.value })} />
              </label>
            </Space>
          </Panel>
          <Panel header="TransactionInfo" key={"TransactionInfo"}>
            <p>Solution: <Tag>ClariCT.AI</Tag></p>
            <p>History:</p>
            <Table
              dataSource={transaction?.history.map((h, i) => { h.key = i; return h })}
              columns={[{ key: "status", dataIndex: "status", title: "status" }, { key: "timestamp", dataIndex: "timestamp", title: "timestamp", render: (_, r) => { return r.timestamp.toDate().toISOString().replace('T', ' ').substring(0, 19) } }]}
              pagination={false}
            />
          </Panel>
        </SummaryBase>
      </div>
    </section>
  )
}

export default TransactionDetails