import { httpsCallable } from "firebase/functions";
import { useContext, useRef } from "react";
import { useState } from "react";
import { FirebaseContext } from "../FirebaseContext";
import { signInWithCustomToken, browserLocalPersistence } from "firebase/auth";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { Card, Input, Button, Alert, Space } from "antd";
import styles from "./SignIn.module.css"

const SignIn = () => {
  const [licenseKey, setLicenseKey] = useState("")
  const [message, setMessage] = useState("")
  const [isAutoSignIn, SetIsAutoSignIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { firebase, setLicense, authUser, setAuthUser } = useContext(FirebaseContext)
  const navigate = useNavigate()
  const location = useLocation()
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!licenseKey) {
      setMessage("Please enter license key")
      return
    }
    setMessage("")
    setIsLoading(true)

    const licenseAuth = httpsCallable(firebase.functions, "licenseAuth");
    licenseAuth({ licenseKey }).then((result) => {
      // setLicense(result.data.license)
      firebase
        .auth
        .setPersistence(
          browserLocalPersistence
        ).then(() => {
          signInWithCustomToken(firebase.auth, result.data.token).then(credential => {
            setAuthUser(credential.user)
            let from = location.state?.from?.pathname || "/transactions";
            navigate(from, { replace: true });
          })
        })
    }).catch(err => {
      console.log(err.message)
      setMessage(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    // if (inputRef) inputRef.current.focus()
  }, [inputRef])

  useEffect(() => {
    if (authUser) {
      let from = location.state?.from?.pathname || "/transactions";
      navigate(from, { replace: true });
    }
  }, [authUser])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const licenseKey = params.get('licenseKey');
    if (licenseKey) {
      setLicenseKey(licenseKey)
      SetIsAutoSignIn(true)
    }
  }, [location])

  useEffect(() => {
    if (licenseKey && isAutoSignIn) {
      handleSubmit()
      SetIsAutoSignIn(false)
    }
  }, [isAutoSignIn, licenseKey])

  return (
    <section className={styles.section}>
      <div className={styles.contents}>
        <Card title="Enter License Key">
          <form onSubmit={handleSubmit}>
            <Input.Group compact>
              <Input
                // ref={inputRef}
                style={{
                  width: 'calc(100% - 100px)',
                }}
                status={message ? "error" : null}
                value={licenseKey}
                onChange={e => setLicenseKey(e.target.value)}
              />
              <Button type="primary" htmlType="submit" style={{ width: "100px" }} loading={isLoading}>Submit</Button>
            </Input.Group>
            <br />
            {message &&
              <Alert message={message} type="error" showIcon />
            }
          </form>
        </Card>
        <Alert
          message="For demo request, please contact david3014@claripi.com"
        />
      </div>
    </section>
  )
}

export default SignIn;