import { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, Outlet } from "react-router-dom"
import { Layout as AntdLayout, Menu, Alert } from 'antd';
import Marquee from 'react-fast-marquee'
import { FirebaseContext } from '../FirebaseContext';
import logo from "../images/main-logo-1.png"
import { logEvent } from 'firebase/analytics';
import { isMobile } from 'react-device-detect';


const { Header, Content, Footer } = AntdLayout;

const routes = [
  { path: "/" },
  { path: "/aboutus" },
  { path: "/contactus" },
  { path: "/transactions/new" },
  { path: "/transactions" },
  { path: "/signin" },
]

const Layout = () => {
  const [state, setState] = useState({
    selectedNavIndex: null,
  })
  const [mobileDataWarning, setMobileDataWarning] = useState(false)

  let { firebase, license, authUser, setAuthUser, authPending } = useContext(FirebaseContext)

  let navigate = useNavigate();

  let location = useLocation();

  let spaceRef = useRef();

  let doLogout = async () => {
    await firebase.auth.signOut()
    setAuthUser(null)
    navigate("/")
  }

  const handleWarningClose = () => {
    setMobileDataWarning(false)
    localStorage.setItem("mobileDataWarning", "true")
  }

  // licenseKey를 searchparams로 받게되면 자동 로그인
  useEffect(() => {
    if (location.search && location.pathname !== "/signin") {
      if (!authPending && !authUser) {
        const params = new URLSearchParams(location.search);
        const licenseKey = params.get('licenseKey');
        if (licenseKey) navigate({
          pathname: '/signin',
          search: location.search,
        })
      }
    }
    if (location.pathname.startsWith("/transactions")) {
      if (isMobile) {
        const prevClosed = localStorage.getItem("mobileDataWarning")
        if (!prevClosed) setMobileDataWarning(true)
      }
    }
  }, [location, authUser, authPending])

  useEffect(() => {
    let selectedNavIndex = null
    routes.forEach((route, index) => {
      if (location.pathname === route.path) {
        selectedNavIndex = String(index)
      }
    })
    setState(s => { return { ...s, selectedNavIndex } })


    logEvent(firebase.analytics, 'screen_view', {
      firebase_screen: location.pathname,
    });
  }, [location.pathname])

  const MenuItemSpace = () => <li style={{ flexGrow: "1", order: "5" }} />

  return (
    <AntdLayout className="layout root-container">
      <Header>
        <img className='logo' src={logo} alt="logo" style={{ height: "30px" }} />
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[state.selectedNavIndex]}
        >
          <Menu.Item key="0" onClick={() => { navigate("/") }}>
            Home
          </Menu.Item>
          <Menu.Item key="1" onClick={() => { window.open("https://www.claripi.com/") }}>
            About us
          </Menu.Item>
          <Menu.Item key="2" onClick={() => { window.open("https://www.claripi.com/contact-us/") }}>
            Contact us
          </Menu.Item>
          <Menu.Item key="3" onClick={() => { navigate("/transactions/new") }}>
            Start demo
          </Menu.Item>
          <Menu.Item key="4" onClick={() => { navigate("/transactions") }}>
            Results
          </Menu.Item>
          <MenuItemSpace />
          {authUser ?
            <Menu.Item key="5" onClick={doLogout}>
              Sign out
            </Menu.Item>
            :
            <Menu.Item key="5" onClick={() => navigate("/signin")}>
              Sign in
            </Menu.Item>
          }
        </Menu>
      </Header>
      <Content>
        {mobileDataWarning &&
          <Alert message={
            <Marquee gradient={false}>
              <span style={{ color: "red" }}>Warning!</span>&nbsp;The demo requires large data bandwidth usage. It is recommended to use via Wi-Fi or to view from a PC browser.&nbsp;&nbsp;
            </Marquee>
          } type="warning" showIcon closable onClose={handleWarningClose} />
        }
        <Outlet />
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Copyright © 2022 ClariPi Inc. All Rights Reserved.
      </Footer>
    </AntdLayout>
  )
};

export default Layout;