import { useRef, useEffect, useState, useContext } from "react";
import { Collapse, Typography, Spin, Slider } from "antd"
import cornerstone from "cornerstone-core";
import TAG_DICT from '../dataDictionary';
import { FirebaseContext } from "../FirebaseContext";
import { getDownloadURL, listAll, ref } from "firebase/storage";

const { Panel } = Collapse
const { Title } = Typography

// images: [cornerstoneImageObject]
const DICOMViewerWithSlide = ({ images = [], windowCenter, windowWidth, onChange }) => {
  const [index, setIndex] = useState(1)
  const prevImageLength = usePrevious(images.length)
  const image = images[index - 1]

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (images.length && prevImageLength !== images.length) setIndex(Math.ceil(images.length / 2) || 1)
  }, [images])

  useEffect(() => {
    if (onChange) onChange(index - 1)
  }, [index, onChange])

  return (
    <div>
      <DICOMViewer image={image} windowCenter={windowCenter} windowWidth={windowWidth} />
      <label>
        index: {index} / {Object.keys(images).length}
        <Slider min={1} max={images.length} value={index} onChange={setIndex} />
      </label>
    </div>
  )
}

export const DICOMDualViewer = ({ leftImages = [], rightImages = [], windowCenter, windowWidth, onChange }) => {
  const [index, setIndex] = useState(1)
  const prevImageLength = usePrevious(leftImages.length)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const leftImage = leftImages[index - 1]
  const rightImage = rightImages[index - 1]

  useEffect(() => {
    if (leftImages.length && prevImageLength !== leftImages.length) setIndex(Math.ceil(leftImages.length / 2) || 1)
  }, [leftImages])

  useEffect(() => {
    if (onChange) onChange(index - 1)
  }, [index, onChange])

  return (
    <div style={{ display: "inline-block" }}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <div style={{ flex: "1", minWidth: "200px" }}>
          <Title level={4}>Original:</Title>
          <DICOMViewer image={leftImage} windowCenter={windowCenter} windowWidth={windowWidth} />
        </div>
        <div style={{ flex: "1", minWidth: "200px" }}>
          <Title level={4}>Denoised:</Title>
          <DICOMViewer image={rightImage} windowCenter={windowCenter} windowWidth={windowWidth} />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <label>
          index: {index} / {Object.keys(leftImages).length}
          <Slider min={1} max={leftImages.length} value={index} onChange={setIndex} />
        </label>
      </div>
    </div>
  )
}

const Overlay = ({ tags }) => {
  const [leftTexts, setLeftTexts] = useState([])
  const [rightTexts, setRightTexts] = useState([])

  useEffect(() => {
    let _leftTexts = []

    let manufacturer = ""
    if ("(0008,0070)" in tags) manufacturer = tags["(0008,0070)"]
    _leftTexts.push(manufacturer)

    let ctmodel = ""
    if ("(0008,1090)" in tags) ctmodel = tags["(0008,1090)"]
    _leftTexts.push(ctmodel)

    let kvpmas = ""
    if ("(0018,0060)" in tags) {
      if ("(0018,1152)" in tags) {
        kvpmas = `${tags["(0018,0060)"]} kVp / ${tags["(0018,1152)"]} mAs`
      } else {
        kvpmas = `${tags["(0018,0060)"]} kVp`
      }
    } else if ("(0018,1152)" in tags) {
      kvpmas = `${tags["(0018,1152)"]} mAs`
    }
    _leftTexts.push(kvpmas)

    let slicethickness = ""
    if ("(0018,0050)" in tags) slicethickness = `${tags["(0018,0050)"]} mm`
    _leftTexts.push(slicethickness)

    let kernel = ""
    if ("(0018,1210)" in tags) kernel = `${tags["(0018,1210)"]} Kernel`
    _leftTexts.push(kernel)


    setLeftTexts(_leftTexts)
  }, [tags])

  useEffect(() => {
    let _rightTexts = []

    let patientId = ""
    if ("(0010,0020)" in tags) patientId = tags["(0010,0020)"]
    _rightTexts.push(patientId)

    let patientName = ""
    if ("(0010,0010)" in tags) patientName = tags["(0010,0010)"]
    _rightTexts.push(patientName)

    let patientGender = ""
    if ("(0010,0040)" in tags) {
      if ("(0010,1010)" in tags) {
        patientGender = `${tags["(0010,0040)"]} / ${tags["(0010,1010)"]}`
      } else {
        patientGender = `${tags["(0010,0040)"]}`
      }
    } else if ("(0010,1010)" in tags) {
      patientGender = `${tags["(0010,1010)"]}`
    }
    _rightTexts.push(patientGender)

    let studyDescription = ""
    if ("(0008,1030)" in tags) studyDescription = tags["(0008,1030)"]
    _rightTexts.push(studyDescription)

    let seriesDescription = ""
    if ("(0008,103E)" in tags) seriesDescription = tags["(0008,103E)"]
    _rightTexts.push(seriesDescription)

    setRightTexts(_rightTexts)
  }, [tags])

  return (
    <div style={{ position: "absolute", color: "white", top: "0", width: "100%" }}>
      <div style={{ position: "absolute", padding: "10px", left: "0px", width: "50%" }}>
        {leftTexts.map((t, i) => <div key={i}>{t ? t : "\u00A0"}</div>)}
      </div>
      <div style={{ position: "absolute", margin: "10px", right: "0px", textAlign: "right", width: "50%" }}>
        {rightTexts.map((t, i) => <div key={i}>{t ? t : "\u00A0"}</div>)}
      </div>
    </div>
  )
}

// For single view
export const DICOMViewer = ({ image, windowCenter, windowWidth, tags }) => {
  let element = useRef(null)

  useEffect(() => {
    cornerstone.disable(element);
    cornerstone.enable(element.current)

    return () => {
      cornerstone.disable(element.current)
    }
  }, [])

  useEffect(() => {
    if (element?.current && image) cornerstone.displayImage(element.current, image);
  }, [image, element])

  useEffect(() => {
    if (element?.current) {
      let viewport = cornerstone.getViewport(element.current);
      if (viewport) {
        viewport.voi.windowCenter = windowCenter
        viewport.voi.windowWidth = windowWidth
        cornerstone.setViewport(element.current, viewport);
      }
    }
  }, [element, windowCenter, windowWidth])

  return (
    <div style={{ height: "0px", paddingBottom: "100%", position: "relative" }}>
      <div ref={element} style={{ width: "100%", position: "absolute", height: "100%", visibility: image ? "visible" : "hidden" }}>
        {tags && <Overlay tags={tags} />}
      </div>
      {!(image) &&
        <div style={{ width: "100%", height: "100%", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      }
    </div>
  )
}

// tags
// imageId
export const SummaryBase = ({ children, tags, activeKey = [] }) => {
  const targetTags = {
    Patient: [
      "(0010,0010)", // Patient's Name
      "(0010,0020)", // Patient ID
      "(0010,0030)", // Patient's Birth Date
      "(0010,0040)", // Patient's Sex
      "(0010,1010)", // Patient's Age
      "(0010,2160)", // Ethnic Group
    ],
    Study: [
      "(0008,0020)", // Study Date
      "(0008,0030)", // Study Time
      "(0008,0050)", // Accession Number
      "(0008,1030)", // Study Description
      "(0020,000D)", // Study Instance UID
      "(0020,0010)", // Study ID
    ],
    Series: [
      "(0008,0021)", // Series Date
      "(0008,0031)", // Series Time
      "(0008,0060)", // Modality
      "(0008,103E)", // Series Description
      "(0018,0015)", // Body Part Examined
      "(0018,1030)", // Protocol Name
      "(0020,000E)", // Series Instance UID
      "(0020,0011)", // Series Number
    ],
    Manufacturer: [
      "(0008,0070)", // Manufacturer
      "(0008,1090)", // Manufacturer's Model Name

    ],
    CT: [
      "(0008,0008)", // Image Type
      "(0018,0060)", // KVP
      "(0018,1150)", // Exposure Time
      "(0018,1151)", // X-Ray Tube Current
      "(0018,1152)", // Exposure in mAs
      "(0018,1153)", // Exposure in µAs
      "(0018,1160)", // Filter Type
      "(0018,1210)", // Convolution Kernel
    ],
    ImagePlane: [
      "(0018,0050)", // Slice Thickness
      "(0028,0030)", // Pixel Spacing
    ]
  }

  return (
    <Collapse defaultActiveKey={["image", ...activeKey]}>
      {children}
      {Object.entries(targetTags).map(([category, tagKeys]) => (
        <Panel header={category} key={category}>
          {tagKeys.map(tagKey => tagKey in tags ? <p key={tagKey}>{TAG_DICT[tagKey]?.name}: {tags[tagKey]}</p> : null).filter(v => !!v)}
        </Panel>
      ))}
    </Collapse>
  )
}

export const InputDataSummary = ({ images, tags }) => {
  const [window, setWindow] = useState({
    center: 20,
    width: 400,
  })

  useEffect(() => {
    if (tags && Object.keys(tags).length) {
      setWindow({
        center: tags["(0028,1050)"].split("\\")[0],
        width: tags["(0028,1051)"].split("\\")[0],
      })
    }
  }, [tags])

  return (
    <SummaryBase tags={tags}>
      <Panel header="image" key="image">
        <div style={{ maxWidth: "256px" }}>
          <DICOMViewerWithSlide images={images} windowCenter={window.center} windowWidth={window.width} />
        </div>
      </Panel>
    </SummaryBase>
  )
}

export const TransactionSummary = ({ transaction }) => {
  const [window, setWindow] = useState({
    center: 20,
    width: 400,
  })
  const [leftRefs, setLeftRefs] = useState([])
  const [rightRefs, setRightRefs] = useState([])
  const [leftImages, setLeftImages] = useState([])
  const [rightImages, setRightImages] = useState([])

  const { firebase } = useContext(FirebaseContext)

  const tags = transaction?.input?.tags

  const handleIndexChange = async (index) => {
    if (leftImages.length && !leftImages[index]) {
      const url = await getDownloadURL(leftRefs[index])
      const imageId = `wadouri:${url}`
      const _image = await cornerstone.loadAndCacheImage(imageId)
      let _leftImages = [...leftImages]
      _leftImages.splice(index, 1, _image)
      setLeftImages(_leftImages)

      if (rightImages.length === leftImages.length && !rightImages[index]) {
        const url = await getDownloadURL(rightRefs[index])
        const imageId = `wadouri:${url}`
        const _image = await cornerstone.loadAndCacheImage(imageId)
        let _rightImages = [...rightImages]
        _rightImages.splice(index, 1, _image)
        setRightImages(_rightImages)
      }
    }
  }

  useEffect(() => {
    (async () => {
      const leftRefs = (await listAll(ref(firebase.storage, transaction.input.storagePath))).items
      setLeftRefs(leftRefs)
      if (transaction.output?.storagePath) {
        const rightRefs = (await listAll(ref(firebase.storage, transaction.output.storagePath))).items
        setRightRefs(rightRefs)
      }
    })()
  }, [transaction])

  useEffect(() => {
    (async () => {
      if (leftRefs.length) {
        const previewIndex = Math.ceil(leftRefs.length / 2) - 1 || 0
        const url = await getDownloadURL(leftRefs[previewIndex])
        const imageId = `wadouri:${url}`
        const _image = await cornerstone.loadAndCacheImage(imageId)
        const _leftImages = Array.from(Array(leftRefs.length).keys()).map((e, i) => i === previewIndex ? _image : null)
        setLeftImages(_leftImages)

        if (rightRefs.length === leftRefs.length) {
          const url = await getDownloadURL(rightRefs[previewIndex])
          const imageId = `wadouri:${url}`
          const _image = await cornerstone.loadAndCacheImage(imageId)
          const _rightImages = Array.from(Array(rightRefs.length).keys()).map((e, i) => i === previewIndex ? _image : null)
          setRightImages(_rightImages)
        }
      }
    })()
  }, [leftRefs, rightRefs])

  useEffect(() => {
    if (tags && Object.keys(tags).length) {
      setWindow({
        center: tags["(0028,1050)"].split("\\")[0],
        width: tags["(0028,1051)"].split("\\")[0],
      })
    }
  }, [tags])

  return (
    <SummaryBase tags={tags}>
      <Panel header="image" key="image">
        {(leftRefs.length && leftRefs.length === rightRefs.length) ?
          <DICOMDualViewer leftImages={leftImages} rightImages={rightImages} windowCenter={window.center} windowWidth={window.width} onChange={handleIndexChange} />
          :
          <div style={{ maxWidth: "256px" }}>
            <DICOMViewerWithSlide images={leftImages} windowCenter={window.center} windowWidth={window.width} onChange={handleIndexChange} />
          </div>
        }
      </Panel>
    </SummaryBase>
  )
}