import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { Table, Tag, Button, Space, Card, Descriptions, Collapse, Select, Form, Row, Col, Alert, Popconfirm } from 'antd';
import { FirebaseContext } from '../FirebaseContext';
import { TransactionSummary } from '../components/DICOM';
import { BrowserView, MobileView } from "react-device-detect"
import styles from "./Transactions.module.css"
import { DeleteOutlined } from '@ant-design/icons';

const defaultFilter = {
  datasource: "user",
  bodypart: "all",
  solution: "all",
  status: "all",
}
const Transactions = () => {
  const [transactions, setTransactions] = useState({})
  const [tableData, setTableData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedFilters, setSelectedFilters] = useState(defaultFilter)
  const [isLoaded, setIsLoaded] = useState(false)

  const { firebase, authUser } = useContext(FirebaseContext)
  const navigate = useNavigate()
  const location = useLocation()

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Body Part',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
    },
    {
      title: 'Source',
      dataIndex: 'isSample',
      key: 'isSample',
      render: (_, obj) => obj?.isSample ? "Sample data" : "User data"
    },
    {
      title: 'Solution',
      dataIndex: 'solution',
      key: 'solution',
      render: (obj) => { return <Tag>ClariCT.AI</Tag> }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" ghost onClick={e => handleExpand(record)}>{expandedKeys.includes(record.key) ? "Close" : "Preview"}</Button>
          <Button type="primary" onClick={e => navigate(`/transactions/${record.id}`)}>Full size</Button>
          {!record.isSample &&
            <Popconfirm
              placement="topRight"
              title={"Are you sure you want to delete this result?"}
              onConfirm={e => handleDelete(e, record)}
              onCancel={e => e.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" danger icon={<DeleteOutlined />} onClick={e => e.stopPropagation()} />
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  const searchOptions = {
    "datasource": {
      label: "Data Source",
      options: [
        { key: "all", label: "All" },
        { key: "user", label: "User data" },
        { key: "sample", label: "Sample data" },
      ]
    },
    "bodypart": {
      label: "Body Part",
      options: [
        { key: "all", label: "All" },
        { key: "chest", label: "CHEST" },
        { key: "abdomen", label: "ABDOMEN" },
        { key: "brain", label: "BRAIN" },
      ]
    },
    "solution": {
      label: "Solution",
      options: [
        { key: "all", label: "All" },
        { key: "clarictai", label: "ClariCT.AI" },
        { key: "claripulmo", label: "ClariPulmo" },
        { key: "clarisigmam", label: "ClariSIGMAM" },
      ]
    },
    "status": {
      label: "Status",
      options: [
        { key: "all", label: "All" },
        { key: "ready", label: "Ready" },
        { key: "processing", label: "Processing" },
        { key: "completed", label: "Completed" },
        { key: "error", label: "Error" },
      ]
    },
  }

  const handleDelete = async (e, record) => {
    e.stopPropagation();
    const ref = doc(firebase.db, `licenses/${authUser.uid}/transactions/${record.id}`);
    await setDoc(ref, { isDeleted: true }, { merge: true });
    await getTransactionData()
  }

  const handleExpand = (record) => {
    if (expandedKeys.length) {
      if (expandedKeys[0] === record.key) setExpandedKeys([])
      else setExpandedKeys([record.key])
    } else setExpandedKeys([record.key])
  }

  const getTransactionData = async () => {
    const colRef = collection(firebase.db, `licenses/${authUser.uid}/transactions`)
    const querySnapshot = await getDocs(colRef);
    let transactions = {}
    querySnapshot.forEach((doc) => {
      const transaction = doc.data()
      transactions[doc.id] = transaction
    });
    setTransactions(transactions)
  }

  const displayDescription = (transaction) => {
    let description = "UNKOWN"
    if (transaction.description) {
      description = transaction.description
    } else if ("(0008,1030)" in transaction.input.tags && transaction.input.tags["(0008,1030)"]) {
      if ("(0008,103E)" in transaction.input.tags && transaction.input.tags["(0008,103E)"]) {
        description = transaction.input.tags["(0008,1030)"] + " / " + transaction.input.tags["(0008,103E)"]
      } else {
        description = transaction.input.tags["(0008,1030)"]
      }
    } else if ("(0008,103E)" in transaction.input.tags && transaction.input.tags["(0008,103E)"]) {
      description = transaction.input.tags["(0008,103E)"]
    }
    return description
  }

  const handleSearch = () => {
    let query = ""
    Object.entries(selectedFilters).forEach(([k, v], i) => {
      if (!query) query += "?"
      else query += "&"
      query += `${k}=${v}`
    })
    navigate(`/transactions${query}`, { replace: true })
  }

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      let filters = { ...selectedFilters }
      Object.entries(searchOptions).forEach(([categoryKey, categoryValue], i) => {
        let paramValue = params.get(categoryKey)
        if (paramValue) filters[categoryKey] = paramValue
      })

      setSelectedFilters(filters)
    } else {
      setSelectedFilters(defaultFilter)
    }
    getTransactionData()
  }, [location])

  useEffect(() => {
    if (Object.keys(transactions).length) {
      let _tableData = Object.entries(transactions).map(([k, v]) => {
        const lastEvent = v.history[v.history.length - 1]
        return {
          ...v,
          id: k,
          key: k,
          description: displayDescription(v),
          status: lastEvent.status,
          bodyPart: v.input.tags["(0018,0015)"],
          updatedAt: lastEvent.timestamp.toDate().toISOString().replace('T', ' ').substring(0, 19)
        }
      })

      _tableData = _tableData.filter(record => !record.isDeleted)

      if (selectedFilters.datasource === "user") _tableData = _tableData.filter(record => !record.isSample)
      if (selectedFilters.datasource === "sample") _tableData = _tableData.filter(record => record.isSample)

      if (selectedFilters.bodypart !== "all") _tableData = _tableData.filter(record => record.bodyPart.toLowerCase() === selectedFilters.bodypart.toLowerCase())

      if (selectedFilters.solution !== "all") _tableData = _tableData.filter(record => searchOptions.solution.options.filter(opt => opt.key === selectedFilters.solution)[0]?.label === record.solution)

      if (selectedFilters.status !== "all") _tableData = _tableData.filter(record => searchOptions.status.options.filter(opt => opt.key === selectedFilters.status)[0]?.label === record.status)

      _tableData.sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())

      setTableData(_tableData)
    }
    setIsLoaded(true)
  }, [transactions])

  return (
    <section className={styles.section}>
      <div className={styles.contents}>
        <BrowserView>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Collapse>
              <Collapse.Panel header="Filter results">
                <Form
                  layout="vertical"
                  initialValues={selectedFilters}
                  onValuesChange={e => setSelectedFilters(s => { return { ...s, ...e } })}
                  onFinish={handleSearch}
                  key={Object.values(selectedFilters).join(",")}
                >
                  <Row gutter={[16, 0]}>
                    {Object.entries(searchOptions).map(([k, v], i) =>
                      <Col key={i} xs={24} sm={12} lg={6}>
                        <Form.Item label={v.label} name={k}>
                          <Select style={{ width: '100%' }}>
                            {v.options.map(option =>
                              <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row gutter={[12]}>
                    <Col xs={{ span: 12 }} sm={{ span: 6 }} lg={{ span: 3 }}>
                      <Button type="primary" style={{ width: "100%" }} htmlType="submit">Search</Button>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 6 }} lg={{ span: 3 }}>
                      <Button type="default" style={{ width: "100%" }} onClick={e => navigate("/transactions", { replace: true })}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              </Collapse.Panel>
            </Collapse>
            {(isLoaded && selectedFilters.datasource === "user" && !tableData.length) &&
              <Alert
                message="No results found"
                description={<span>You can view <Link to="/transactions?datasource=sample" replace>sample results</Link></span>}
                type="warning"
                showIcon
              />
            }
            <Table
              columns={columns}
              rowClassName={() => "clickable"}
              onRow={(record) => {
                return {
                  onClick: e => handleExpand(record)
                };
              }}
              expandable={{
                expandedRowKeys: expandedKeys,
                onExpand: (expanded, record) => handleExpand(record),
                expandedRowRender: (record) => (
                  <TransactionSummary transaction={record} />
                ),
                rowExpandable: () => true,
              }}
              dataSource={tableData}
            />
          </div>
        </BrowserView>
        <MobileView>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Collapse>
              <Collapse.Panel header="Filter results">
                <Form
                  layout="vertical"
                  initialValues={selectedFilters}
                  onValuesChange={e => setSelectedFilters(s => { return { ...s, ...e } })}
                  onFinish={handleSearch}
                  key={Object.values(selectedFilters).join(",")}
                >
                  <Row gutter={[16, 0]}>
                    {Object.entries(searchOptions).map(([k, v], i) =>
                      <Col key={i} xs={24} sm={12} lg={6}>
                        <Form.Item label={v.label} name={k}>
                          <Select style={{ width: '100%' }}>
                            {v.options.map(option =>
                              <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row gutter={[12]}>
                    <Col xs={{ span: 12 }} sm={{ span: 6 }} lg={{ span: 3 }}>
                      <Button type="primary" style={{ width: "100%" }} htmlType="submit">Search</Button>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 6 }} lg={{ span: 3 }}>
                      <Button type="default" style={{ width: "100%" }} onClick={e => navigate("/transactions", { replace: true })}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              </Collapse.Panel>
            </Collapse>
            {(isLoaded && selectedFilters.datasource === "user" && !tableData.length) &&
              <Alert
                message="No results found"
                description={<span>You can view <Link to="/transactions?datasource=sample" replace>sample results</Link></span>}
                type="warning"
                showIcon
              />
            }
            {tableData.map((transaction, i) =>
              <Card key={i}>
                <Descriptions title={transaction.description}>
                  {columns.map((column, j) => {
                    if (column.key === "solution") return <Descriptions.Item key={j} label={column.title}><Tag>ClariCT.AI</Tag></Descriptions.Item>
                    if (column.key === "action") return <Descriptions.Item key={j} label={column.title}>
                      <Space size="middle">
                        <Button onClick={() => navigate(`/transactions/${transaction.id}`)} type="primary" size='small'>Full size</Button>
                        {!transaction.isSample &&
                          <Popconfirm
                            placement="top"
                            title={"Are you sure you want to delete this result?"}
                            onConfirm={e => handleDelete(e, transaction)}
                            onCancel={e => e.stopPropagation()}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="text" size="small" danger onClick={e => e.stopPropagation()}>Delete</Button>
                          </Popconfirm>
                        }
                      </Space>
                    </Descriptions.Item>
                    if (column.key === "isSample") return <Descriptions.Item key={j} label={column.title}>{transaction.isSample ? "Sample data" : "User data"}</Descriptions.Item>
                    return <Descriptions.Item key={j} label={column.title}>{transaction[column.dataIndex]}</Descriptions.Item>
                  }
                  )}
                </Descriptions>
              </Card>
            )}
          </div>
        </MobileView>
      </div>
    </section >
  )
};

export default Transactions;