import styles from "./Landing.module.css"
import clarictaiBanner from "../images/banner-clarictai.jpg"
import claripulmoBanner from "../images/banner-claripulmo.jpg"
import clarisigmamBanner from "../images/banner-clarisigmam.jpg"
import headlineBanner from "../images/banner-headline.jpg"
import { Button } from "antd"
import { useNavigate } from "react-router"
import { useState } from "react"

const Landing = () => {
  const [imagesLoaded, setImagesLoaded] = useState([false, false, false, false])
  let navigate = useNavigate()

  const handleImageLoad = (index) => {
    setImagesLoaded(s => {
      let arr = [...s]
      arr[index] = true
      return arr
    })
  }

  return (
    <main className={styles.main__wrapper}>
      <section className={styles.section__wrapper}>
        <img src={headlineBanner} alt="headlineBanner" className={styles.banner} onLoad={e => handleImageLoad(0)} />
        {imagesLoaded[0] &&
          <div className={styles.banner__content__container}>
            <div className={styles.firstview__headline__wrapper}>
              <div className={styles.firstview__headline}>
                Try our solutions<br />
                Realtime on web browsers
              </div>
              <div className={styles.firstview__buttonwrapper}>
                <Button type="primary" size="large" onClick={e => { navigate("/transactions/new") }}>
                  Try Now
                </Button>
              </div>
            </div>
          </div>
        }
      </section>
      <section className={styles.section__wrapper}>
        <img src={clarictaiBanner} alt="clarictaiBanner" className={styles.banner} onLoad={e => handleImageLoad(1)} />
        {imagesLoaded[1] &&
          <div className={styles.banner__content__container}>
            <div className={styles.banner__content}>
              <h1>Clari<span style={{ color: "#D4A03B" }}>CT.AI</span></h1>
              <p>Unprecedented Image Clarity for Ultra-Low-Dose CT Scans</p>
              <Button type="primary" size="large" onClick={e => navigate("/transactions/new")}>Try Now</Button>
            </div>
          </div>
        }
      </section>
      <section className={styles.section__wrapper}>
        <img src={claripulmoBanner} alt="claripulmoBanner" className={styles.banner} onLoad={e => handleImageLoad(2)} />
        {imagesLoaded[2] &&
          <div className={styles.banner__content__container}>
            <div className={styles.banner__content}>
              <h1>Clari<span style={{ color: "#D4A03B" }}>Pulmo</span></h1>
              <p>Fully Automated AI-Powered 3D Reporting Solution for Emphysema and Covid-19 Pneumonia</p>
              <Button type="primary" size="large" disabled>Available soon</Button>
            </div>
          </div>
        }
      </section>
      <section className={styles.section__wrapper}>
        <img src={clarisigmamBanner} alt="clarisigmamBanner" className={styles.banner} onLoad={e => handleImageLoad(3)} />
        {imagesLoaded[2] &&
          <div className={styles.banner__content__container}>
            <div className={styles.banner__content}>
              <h1>Clari<span style={{ color: "#D4A03B" }}>SIGMAM</span></h1>
              <p>AI-Powered Breast Density Measurement Solution</p>
              <Button type="primary" size="large" disabled>Available soon</Button>
            </div>
          </div>
        }
      </section>
    </main>
  )
}

export default Landing