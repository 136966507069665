import { Button, Card, Checkbox, Form, Input } from "antd"
import { doc, setDoc } from "firebase/firestore";
import { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../FirebaseContext";
import styles from "./Registration.module.css"

const Registration = () => {
  const [fields, setFields] = useState({ name: "", email: "", agreed: false })
  const { firebase, authUser, setLicense } = useContext(FirebaseContext)
  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (data) => {
    const userInfo = {
      name: data.name,
      email: data.email,
    }
    await setDoc(doc(firebase.db, "licenses", authUser.uid), {
      userInfo
    }, { merge: true })
    setLicense(s => { return { ...s, userInfo } })
    let from = location.state?.from?.pathname || "/transactions";
    navigate(from, { replace: true });
  }

  return (
    <section className={styles.section}>
      <div className={styles.contents}>
        <Card title="Enter your profile">
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            initialValues={fields}
            onValuesChange={e => setFields(s => { return { ...s, ...e } })}
            onFinish={handleSubmit}
            size={"default"}
          >
            <Form.Item
              label="Full name"
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  max: 100,
                  type: "string",
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                {
                  required: true,
                },
                {
                  max: 100,
                  type: "string",

                }
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              style={{ padding: "0 20px" }}
              name="agreed"
              valuePropName="checked"
              wrapperCol={{
                span: 22,
              }}
              rules={[
                {
                  required: true,
                  message: "Please agree to our terms and conditions"
                }
              ]}
            >
              <Checkbox>By checking the box, you are confirming that you have read, understood, and agree to ClariPi’s <Link to="/termsandconditions.html" target="_blank" rel="noopener noreferrer">terms and conditions</Link>.</Checkbox>
            </Form.Item>

            <Form.Item
              style={{ marginTop: "20px" }}
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" disabled={!fields.agreed}>
                Start demo
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </section >
  )
}

export default Registration